import React from "react";
import { Fragment, useEffect, useState } from "react";
import HomeRoutes from "./home";

const AppRoutes = () => {
  const [user, setUser] = useState();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token !== null || token !== "null") {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  return (
    <Fragment>
      <HomeRoutes />
    </Fragment>
  );
};
export default AppRoutes;
